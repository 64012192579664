import React from 'react'
import { Trans } from '@lingui/react'
import compose from 'lodash/fp/compose'

import './index-layout-intro.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import deviceHelper from 'shared/tools/device-helper'
import withMarketing, {
  MarketingProps,
} from 'client/shared/decorators/with-marketing'
import { IndexLayoutStorebuttons } from '../index-layout-storebuttons'

type Props = {
  kind: 'premium' | 'audio'
  button: React.ReactNode
  children?: React.ReactNode
} & DecoratorProps &
  MarketingProps

class IndexLayoutIntro extends React.Component<Props> {
  static defaultProps = {
    kind: 'premium',
  }

  getTitleText(): JSX.Element {
    return <Trans id="landing.intro_title" />
  }

  render(): JSX.Element {
    const { kind, children } = this.props

    return (
      <div className={`index-layout-intro index-layout-intro_${kind}`}>
        {children ? children : this.renderDefaultIntro()}
      </div>
    )
  }

  renderDefaultIntro() {
    const { app, button } = this.props
    const isMobile = deviceHelper.isMobileSize(app)
    const isMobileOS = deviceHelper.isMobileOS(app)

    return (
      <div>
        {!isMobile && (
          <h1 className="index-layout-intro__title">{this.getTitleText()}</h1>
        )}
        <p className="index-layout-intro__subtitle">
          <Trans id="landing.intro_subtitle" />
        </p>
        {button}
        {!isMobileOS && (
          <div className="index-layout-intro__stores">
            <IndexLayoutStorebuttons stage="main_landing_top" />
          </div>
        )}
      </div>
    )
  }
}

const wrappers = compose(uiBox, withMarketing)

export default wrappers(IndexLayoutIntro)
