import React, { Component } from 'react'
import StoreButtonBox from 'client/shared/boxes/store-button-box'

import './index-layout-storebuttons.styl'

type Props = {
  stage?: string
}

export class IndexLayoutStorebuttons extends Component<Props> {
  render(): JSX.Element {
    const { stage } = this.props

    return (
      <div className="index-layout-storebuttons__wrapper">
        <div className="index-layout-storebuttons__button">
          <StoreButtonBox platform="ios" stage={stage} inverted size="small" />
        </div>
        <div className="index-layout-storebuttons__button">
          <StoreButtonBox
            platform="android"
            stage={stage}
            inverted
            size="small"
          />
        </div>
      </div>
    )
  }
}
