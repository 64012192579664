import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './index-layout-showcase.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { IndexSection } from 'client/bookmate/blocks/index-section'
import HorizontalScroller from 'client/shared/blocks/horizontal-scroller'
import Resource from 'client/shared/blocks/resource'

import { ResourceProps } from 'client/shared/types/resource'

type Props = DecoratorProps & {
  items: ResourceProps[]
}

class IndexLayoutShowcase extends Component<Props> {
  render(): JSX.Element {
    const { isDesktopAllSizes } = this.props

    return (
      <div className="index-layout-showcase">
        <IndexSection bgColor="#f3f6f9">
          <h2 className="index-layout-showcase__title">
            <Trans id="landing.showcase_title" />
          </h2>
          {isDesktopAllSizes() ? (
            <div className="index-layout-showcase__books">
              {this.renderResources()}
            </div>
          ) : (
            <HorizontalScroller>{this.renderResources()}</HorizontalScroller>
          )}
        </IndexSection>
      </div>
    )
  }

  renderResources(): JSX.Element[] {
    const { items, isDesktopAllSizes } = this.props

    return items
      .slice(0, 12)
      .map((item, i) => (
        <Resource
          item={item}
          kind="grid"
          key={i}
          isDesktop={isDesktopAllSizes()}
        />
      ))
  }
}

export default uiBox(IndexLayoutShowcase)
