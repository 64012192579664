import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import compose from 'lodash/fp/compose'

import config from 'config'

import prepareComponent from 'client/shared/decorators/prepare-component'
import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import urlFor, { QueryParams } from 'shared/tools/url-helper'

import { getPosts } from 'client/bookmate/reducers/posts-reducer'
import { getItemsFromPosts } from 'client/shared/helpers/resource-helpers'

import { loadPosts } from 'client/bookmate/reducers/shelf-reducer'

import MetaTitle from 'client/shared/blocks/meta-title'
import { MetaDescription } from 'client/shared/blocks/meta-description'
import MetaDeeplink from 'client/shared/blocks/meta-deeplink'

import HeaderBox from 'client/shared/boxes/header-box'
import IndexLayout from 'client/bookmate/blocks/index-layout'
import IndexFooter from 'client/bookmate/blocks/index-footer'

import indexShowcaseShelves from 'client/bookmate/assets/data/landing/index-showcase-shelves.json'

import { Dispatch, State } from 'shared/types/redux'
import { CurrentUserState } from 'client/shared/types/current-user'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import { ResourceProps } from 'client/shared/types/resource'
import { GHANA } from 'client/shared/reducers/current-user-reducer'

type Props = {
  dispatch: Dispatch
  currentUser: CurrentUserState
  items: ResourceProps[]
  subscription: SubscriptionState
  query?: QueryParams
} & withI18nProps

class IndexPage extends Component<Props> {
  componentDidUpdate() {
    const {
      dispatch,
      currentUser: {
        auth,
        data: { country },
      },
      query,
    } = this.props

    if (country === GHANA && !auth) dispatch(push(urlFor.mtnPage(query)))

    if (auth) dispatch(push(urlFor.library(query)))
  }

  render() {
    const { items, query } = this.props
    const theme = undefined

    return (
      <>
        {this.renderMeta()}
        <HeaderBox />
        <IndexLayout items={items} theme={theme} query={query} />
        <IndexFooter />
      </>
    )
  }

  renderMeta() {
    const { i18n } = this.props
    const headTitle = i18n.t`landing.head_title`
    const metaDescription = i18n.t`landing.meta_description`
    const ogTitle = i18n.t`landing.og_title`
    const ogDescription = i18n.t`landing.og_description`

    return (
      <>
        <MetaTitle disableSuffix title={headTitle} og={ogTitle} />
        <MetaDescription description={metaDescription} og={ogDescription} />
        <MetaDeeplink target="showcase" />
      </>
    )
  }
}

const prepareComponentWrapper = prepareComponent(
  async ({ store }) => {
    const { dispatch, getState } = store
    const {
      app: { query },
      currentUser: { auth, data },
    } = getState()

    if (auth) {
      serverRedirectTo(urlFor.library(query))
    }

    const uuid =
      indexShowcaseShelves[data.locale] ||
      indexShowcaseShelves[config.fallbackLocale]

    const loadShowcaseResources = [
      dispatch(loadPosts({ uuid, pp: 12, page: 1 })),
    ]

    await Promise.all(loadShowcaseResources)
  },
  {
    userListener: true,
  },
)

const wrappers = compose(
  prepareComponentWrapper,
  withI18n({ update: true }),
  connect((state: State) => ({
    query: state.app.storedQuery,
    currentUser: state.currentUser,
    subscription: state.subscription,
    items: getItemsFromPosts(getPosts(state.shelf.posts, state.entities)),
  })),
)

export default wrappers(IndexPage)
