import React, { Component } from 'react'

import AudioBook from 'client/bookmate/blocks/audiobook'
import { Book } from 'client/shared/blocks/book'
import { Comicbook } from 'client/shared/blocks/comicbook'

import { BookProps } from 'client/shared/types/book'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { ResourceProps } from 'client/shared/types/resource'

type Kind = 'grid' | 'list'

type SliderProps = {
  isInSlider?: boolean
  coverSize?: number
}

type Props = {
  item: ResourceProps
  kind: Kind
  shouldShowCover: boolean
  shouldShowMetaCounters: boolean
  shouldShowBadges: boolean
  isDesktop?: boolean
}

class Resource extends Component<Props> {
  static defaultProps = {
    kind: 'list',
    shouldShowCover: true,
    shouldShowMetaCounters: true,
    shouldShowBadges: true,
  }

  render(): JSX.Element | null {
    const { item, kind, isDesktop } = this.props

    const sliderProps: SliderProps = {}
    if (!isDesktop) {
      sliderProps.isInSlider = true
      sliderProps.coverSize = 120
    }

    switch (item.resourceType) {
      case 'book':
        return this.renderBook(item, kind, sliderProps)

      case 'audiobook':
        return this.renderAudiobook(item, kind)

      case 'comicbook':
        return this.renderComicbook(item, kind)

      default:
        return null
    }
  }

  renderBook(
    book: BookProps,
    kind: Kind,
    sliderProps?: SliderProps,
  ): JSX.Element {
    const {
      shouldShowCover,
      shouldShowMetaCounters,
      shouldShowBadges,
    } = this.props

    return (
      <Book
        key={book.uuid}
        book={book}
        kind={kind}
        shouldShowCover={shouldShowCover}
        shouldShowMetaCounters={shouldShowMetaCounters}
        shouldShowBadges={shouldShowBadges}
        {...sliderProps}
      />
    )
  }

  renderAudiobook(audiobook: AudiobookProps, kind: Kind): JSX.Element {
    return <AudioBook key={audiobook.uuid} audiobook={audiobook} kind={kind} />
  }

  renderComicbook(comicbook: ComicbookProps, kind: Kind): JSX.Element {
    const {
      shouldShowCover,
      shouldShowMetaCounters,
      shouldShowBadges,
    } = this.props

    return (
      <Comicbook
        key={comicbook.uuid}
        comicbook={comicbook}
        kind={kind}
        shouldShowCover={shouldShowCover}
        shouldShowMetaCounters={shouldShowMetaCounters}
        shouldShowBadges={shouldShowBadges}
      />
    )
  }
}

export default Resource
