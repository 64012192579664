import React from 'react'
import { Trans } from '@lingui/react'
import compose from 'lodash/fp/compose'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import withMarketing, {
  MarketingProps,
} from 'client/shared/decorators/with-marketing'

import Spacer from 'client/shared/blocks/spacer'

import './index-layout-outro.styl'

type Props = {
  kind: 'premium' | 'audio'
  button: React.ReactNode
  children?: React.ReactNode
} & DecoratorProps &
  MarketingProps

class IndexLayoutOutro extends React.Component<Props> {
  static defaultProps = {
    kind: 'premium',
  }

  getTitleText(): JSX.Element {
    return <Trans id="landing.intro_title" />
  }

  render(): JSX.Element {
    const { kind, button, children } = this.props

    if (children) {
      return (
        <div className={`index-layout-outro index-layout-outro_${kind}`}>
          {children}
        </div>
      )
    }

    return (
      <div className={`index-layout-outro index-layout-outro_${kind}`}>
        <h3 className="index-layout-outro__title">{this.getTitleText()}</h3>
        <Spacer size={32} />
        {button}
      </div>
    )
  }
}

const wrappers = compose(uiBox, withMarketing)

export default wrappers(IndexLayoutOutro)
