import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import { IndexSection } from 'client/bookmate/blocks/index-section'
import IndexLayoutScreen from '../index-layout-screen'

import './index-layout-audio.styl'

export class IndexLayoutAudio extends Component {
  render(): JSX.Element {
    return (
      <div className="index-layout-audio">
        <IndexSection type="flex" bgColor="#faeddf">
          <IndexLayoutScreen kind="audio" />
          <div className="index-layout-audio__content">
            <div className="index-layout-audio__badge">
              <Trans id="landing.audio" />
            </div>
            <h3 className="index-layout-audio__title">
              <Trans id="landing.audio_title" components={[<br />]} />
            </h3>
            <div className="index-layout-audio__text">
              <Trans id="landing.audio_text" />
            </div>
          </div>
        </IndexSection>
      </div>
    )
  }
}
