import React, { PureComponent } from 'react'

import deeplinkHelper from 'client/shared/helpers/deeplink-helper'

import Meta from 'client/shared/blocks/meta'

type Props = {
  target: string
  uuid?: string
}

class MetaDeeplink extends PureComponent<Props> {
  render() {
    const { target, uuid } = this.props
    const appUrl = deeplinkHelper.getAppLink(target, uuid)

    return (
      <>
        <Meta content={appUrl} property="al:ios:url" />
        <Meta content={appUrl} property="al:android:url" />
        <Meta content={appUrl} property="twitter:app:url:iphone" />
        <Meta content={appUrl} property="twitter:app:url:googleplay" />
      </>
    )
  }
}

export default MetaDeeplink
