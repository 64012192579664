import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './index-layout-free.styl'

import { IndexSection } from 'client/bookmate/blocks/index-section'
import IndexLayoutScreen from '../index-layout-screen'

export default class IndexLayoutFree extends Component {
  render(): JSX.Element {
    return (
      <IndexSection type="mirrored">
        <IndexLayoutScreen kind="free" />
        <div className="index-layout-free__content">
          <h3 className="index-layout-free__title">
            <Trans id="landing.free_title" />
          </h3>
          <div className="index-layout-free__text">
            <Trans id="landing.free_text" />
          </div>
        </div>
      </IndexSection>
    )
  }
}
