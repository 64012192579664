import React, { Component } from 'react'
import './index-layout.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import StoreButtonBox from 'client/shared/boxes/store-button-box'

import SubscriptionButton from 'client/shared/blocks/subscription-button'
import IndexLayoutIntro from './index-layout-intro'
import IndexLayoutShowcase from './index-layout-showcase'
import IndexLayoutLibrary from './index-layout-library'
import IndexLayoutFree from './index-layout-free'
import { IndexLayoutAudio } from './index-layout-audio'
import { IndexLayoutUpload } from './index-layout-upload'
import IndexLayoutReader from './index-layout-reader'
import { IndexLayoutMedia } from './index-layout-media'
import { IndexLayoutFeatures } from './index-layout-features'
import IndexLayoutFeed from './index-layout-feed'
import IndexLayoutReviews from './index-layout-reviews'
import IndexLayoutOutro from './index-layout-outro'

import { ResourceProps } from 'client/shared/types/resource'
import { QueryParams } from 'shared/tools/url-helper'
import { MTNLayout } from '../mtn-layout/mtn-layout'

const MAIN_STAGE_LINK = 'main_landing_top'
const FOOTER_STAGE_LINK = 'main_landing_bottom'

type Props = DecoratorProps & {
  items: ResourceProps[]
  theme?: string | null | undefined
  query?: QueryParams
}

class IndexLayout extends Component<Props> {
  render(): JSX.Element {
    const { items, locale, country, theme, query } = this.props

    if (['gh'].includes(country)) return <MTNLayout hideHeader />

    return (
      <div className="index-layout">
        {this.renderLayoutIntro()}
        {Boolean(items.length) && (
          <IndexLayoutShowcase query={query} items={items} />
        )}
        <IndexLayoutLibrary country={country} />
        {['ru', 'en', 'es', 'de'].includes(locale) && <IndexLayoutFree />}
        {['ru', 'da'].includes(locale) && <IndexLayoutAudio />}
        <IndexLayoutUpload />
        <IndexLayoutReader />
        <IndexLayoutMedia />
        <IndexLayoutFeatures />
        <IndexLayoutFeed />
        <IndexLayoutReviews locale={locale} />
        <IndexLayoutOutro
          kind={theme}
          button={this.renderButton(FOOTER_STAGE_LINK)}
        />
      </div>
    )
  }

  renderLayoutIntro(): JSX.Element {
    const { theme } = this.props
    return (
      <IndexLayoutIntro
        kind={theme}
        button={this.renderButton(MAIN_STAGE_LINK)}
      />
    )
  }
  renderButton(stage: string): JSX.Element {
    const { isMobileOS } = this.props

    if (isMobileOS()) {
      return this.renderStoreButton(stage)
    } else {
      return this.renderSubscriptionButton()
    }
  }

  renderSubscriptionButton(): JSX.Element {
    return (
      <div className="index-layout__button">
        <SubscriptionButton />
      </div>
    )
  }

  renderStoreButton(stage: string): JSX.Element {
    return (
      <div className="index-layout__button">
        <StoreButtonBox inverted stage={stage} />
      </div>
    )
  }
}

export default uiBox(IndexLayout)
